
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
    
@import '@/styles/font.scss';
.login {
    width: 1000px;
    margin: 0 auto;
    :global {
        .login-box {
            display: flex;
            margin: 60px 0px 245px;
            border-radius: 20px;
            background: #fff;
            overflow-y: hidden;
            position: relative;
            transition: all 0.5s linear;

            .ant-row > .ant-row {
                display: block !important;
                width: 100%;
            }

            .left {
                position: absolute;
                top: 0;
                left: 0;
                width: 400px;
                height: 679px;
            }

            .right {
                padding: 42px 100px 0 500px;
                min-height: 679px;
                .content-wrapper {
                    width: 400px;
                }
                h1 {
                    width: 100%;
                    font-size: 24px;
                    text-align: left;
                    font-family: var(--ff-woff2-B);
                }

                .login-form {
                    margin: 20px 0 0;
                    max-width: 400px;

                    .inputItem {
                        position: relative;
                        margin-bottom: 6px;

                        label {
                            position: absolute;
                            top: 16px;
                            left: 16px;
                            pointer-events: none;
                            -webkit-user-select: none;
                            -moz-user-select: none;
                            -ms-user-select: none;
                            user-select: none;
                            z-index: 100;
                            transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
                            transition-duration: 0.3s;
                            color: #afafaf;
                            font-size: 14px;
                            line-height: normal;
                            html[site='sa'] & {
                                left: 0;
                                right: 0.15rem;
                            }
                        }

                        .active {
                            top: 4px;
                            left: 16px;
                            font-size: 12px;
                        }
                    }

                    .form-item1 {
                        margin: 20px auto;
                    }

                    .default-input {
                        &::placeholder {
                            html[site='sa'] & {
                                text-align: right;
                            }
                        }
                        &:hover {
                            border-color: #121212 !important;
                        }

                        width: 400px;
                        height: 48px;
                        position: relative;
                        padding: 12px 0 0 16px;
                        border-color: #b8b8b8;
                        border-radius: 8px;
                        font-size: 14px;
                        transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
                        transition-property: font-size;
                        color: rgb(0, 0, 0);
                        // font-size: 1px;
                        // line-height: 24px;
                    }

                    .password-item {
                        .ant-form-item {
                            margin-bottom: 0;
                        }
                    }
                    .inline-item {
                        text-align: right;
                        &.validate-error {
                            transform: translateY(12px);
                        }
                        &.hide-text {
                            visibility: hidden;
                        }

                        .login-form-inline-forgot {
                            color: #999;
                            font-family: var(--ff-woff2-M);
                            font-size: 12px;
                            &:hover {
                                color: #000000;
                            }
                        }
                    }

                    .ant-input-password input {
                        margin-top: 8px;
                        font-size: 14px;
                    }

                    .ant-form-item-has-error {
                        .default-input {
                            border-color: #f03e3e !important;
                        }
                    }

                    .ant-input-suffix {
                        margin: 0 10px 0px 10px;
                    }

                    .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
                    .ant-input-affix-wrapper-focused {
                        box-shadow: none;
                    }

                    .ant-form-item-explain-error {
                        padding-left: 16px;
                        margin-bottom: 6px;
                    }

                    .addClass {
                        &::placeholder {
                            position: absolute;
                            display: block !important;
                            top: 5px;
                            font-size: 14px;
                            color: var(--navbg);
                        }
                    }

                    .black-btn {
                        background: var(--navbg) !important;
                        border-color: var(--navbg) !important;

                        &:hover,
                        &:visited,
                        &:focus,
                        &:active {
                            background: var(--cBlack) !important;
                            border-color: var(--cBlack) !important;
                        }
                    }

                    .login-form-button {
                        width: 400px;
                        height: 48px;
                        font-size: 16px;
                        border-radius: 8px;
                        font-family: var(--ff-woff2-B);
                    }

                    .login-form-button:disabled {
                        border: none;
                        color: #03060b !important;
                        background-color: #f5f5f5 !important;
                    }

                    .login-form-button:disabled:hover {
                        background-color: #f5f5f5 !important;
                    }

                    .login-btn:not([disabled]):hover,
                    .login-btn:not([disabled]):focus {
                        background-color: #444 !important;
                        border-color: #444 !important;
                    }

                    .line {
                        position: relative;
                        text-align: center;
                        line-height: 20px;
                        color: #757575;
                    }

                    .line:before,
                    .line:after {
                        position: absolute;
                        background: #cdd6e0;
                        content: '';
                        height: 1px;
                        top: 50%;
                        width: 33%;
                        opacity: 0.8;

                        html[site='jp'] & {
                            width: 8%;
                        }

                        html[site='de'] & {
                            width: 25%;
                        }

                        html[site='fr'] & {
                            width: 25%;
                        }

                        html[site='cn'] & {
                            width: 25%;
                        }

                        html[site='it'] & {
                            width: 25%;
                        }
                    }

                    .line:before {
                        left: 0px;
                    }

                    .line:after {
                        right: 0px;
                    }

                    .fb-login-box {
                        background: none;
                        padding: 0px;
                    }

                    .google-login-box {
                        box-shadow: none !important;
                        border: none !important;

                        div {
                            display: none;
                        }
                    }

                    .fb-btn {
                        background: #3e5999;
                        border-color: #3e5999;
                    }

                    .ga-btn {
                        margin-top: 0px;
                        background: #5086ec;
                        border-color: #5086ec;
                    }

                    .line-btn {
                        color: #fff;
                        margin-top: 0px;
                        background: #06c755;
                        border-color: #06c755;
                        &:hover {
                            background: #3ace01 !important;
                            border-color: #3ace01 !important;
                        }
                    }

                    .bb {
                        width: 1px;
                        height: 22px;
                        margin-right: 2px;
                        background-color: #cdd6e0;

                        html[site='jp'] & {
                            margin: 0 5px;
                        }
                    }

                    .login-form-register,
                    .login-form-forgot {
                        font-family: var(--ff-woff2-M);
                        font-weight: 400;
                        display: inline-flex;
                        width: calc(50% - 1px);
                        white-space: nowrap;
                        cursor: pointer;

                        html[site='jp'] & {
                            font-size: 13px;
                        }
                    }
                }

                .login-form-register-button {
                    width: 100%;
                    height: 48px;
                    padding: 13px 0;
                    text-align: center;
                    font-family: var(--ff-woff2-B);
                    font-size: 16px;
                    color: #000;
                    border-radius: 4px;
                    border: 1px solid #000;
                    line-height: 1;
                    cursor: pointer;
                    &:hover {
                        opacity: 0.9;
                    }
                }

                .divider-line {
                    position: relative;
                    text-align: center;
                    line-height: 20px;
                    color: #999;
                    font-size: 12px;
                    margin: 14px auto;
                    font-family: var(--ff-woff2-M);
                }

                .ecocredit-desc {
                    .ecocredit-desc-title {
                        color: #000;
                        text-align: center;
                        font-family: var(--ff-woff2-B);
                        font-size: 16px;
                        white-space: pre-wrap;
                        html[site='jp'] & {
                            font-size: 15px;
                        }
                    }
                    .ecocredit-content {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        margin: 20px auto;
                        .ecocredit-content-item {
                            width: 113px;
                        }
                        .ecocredit-content-item-icon {
                            text-align: center;
                            margin-bottom: 8px;
                        }
                        .ecocredit-content-item-text {
                            font-size: 12px;
                            font-family: var(--ff-woff2-M);
                            color: #000;
                            text-align: center;
                            html[site='jp'] & {
                                white-space: pre-line;
                            }
                            html[site='kr'] & {
                                white-space: pre-line;
                            }
                        }
                    }
                }

                .items-oterplatform-login {
                    width: 400px;
                    display: flex;
                    justify-content: center;
                    gap: 16px;
                    html[site='jp'] & {
                        justify-content: space-between;
                    }
                    .google-link {
                        flex: 1 0 auto;
                        &.full_width {
                            flex: 1 0 100%;
                        }
                    }
                    .line-link {
                        flex: 1 0 auto;
                    }
                    .oterplatform-button {
                        width: 100%;
                        height: 48px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 14px;
                        font-family: var(--ff-woff2-B);
                        color: #000;
                        flex: 1 0 auto;
                    }
                }

                .items-protocols {
                    text-align: center;
                    color: #707070;
                    font-size: 12px;
                    font-family: var(--ff-woff2-M);
                    line-height: 1.2;
                    margin-top: 6px;
                    > a {
                        text-decoration: underline;
                    }
                }
            }

            .iconfont {
                width: 16px;
                height: 16px;
                display: inline-block;
                cursor: pointer;

                &.icon-eye-in {
                    background: url('https://websiteoss.ecoflow.com/icons/4aa3f5a4a257c858b07761b087177c9a.svg') no-repeat center/100% 100%;

                    &:hover {
                        background: url('https://websiteoss.ecoflow.com/icons/196f1bfc9d6acb9a7b70a5ca9d8bc7c4.svg') no-repeat center/100% 100%;
                    }
                }

                &.icon-eye-out {
                    background: url('https://websiteoss.ecoflow.com/icons/22ee15555ffc460f744e298b07cc263f.svg') no-repeat center/100% 100%;

                    &:hover {
                        background: url('https://websiteoss.ecoflow.com/icons/ak-out-hover.svg') no-repeat center/100% 100%;
                    }
                }
            }

            .error-tip {
                position: absolute;
                left: 16px;
                bottom: 0;
                transform: translateY(100%);
                color: #ff4d4f;
                line-height: 1.4;
            }

            /* 自动填充的背景色修正 */
            input:-internal-autofill-previewed,
            input:-internal-autofill-selected {
                -webkit-text-fill-color: #000 !important;
                transition: background-color 5000s ease-in-out 0s !important;
            }

            .form-item2 {
                display: flex;
                width: 100%;
                justify-content: space-around;
                margin-bottom: 20px;
                .ant-row {
                    width: 100%;
                }
                .ant-form-item-control-input-content {
                    display: flex;
                    width: 100%;
                    justify-content: space-around;
                }
            }
            .form-item-share {
                margin-bottom: 20px;
                a {
                    display: block;
                    width: 100%;
                }
            }
        }
        .isHomebattery {
            .form-item-share {
                display: none !important;
            }
            .form-item3 {
                display: none !important;
            }
            .right {
                min-height: 667px;
            }
        }
    }

    @include maxWidth('lg') {
        & {
            width: 100%;
        }
        :global {
            .login-box {
                margin: 0;
            }

            .login-box .left {
                display: none;
            }

            .login-box .right {
                width: 100%;
                margin: 0 auto;
                padding: 48px 24px;
                min-height: 100vh;
            }

            .login-box .right {
                .content-wrapper {
                    width: 100%;
                }
                h1 {
                    font-size: 24px;
                    letter-spacing: -0.8px;
                }
                .items-oterplatform-login {
                    width: 100%;
                    a {
                        display: inline-block;
                        width: 100%;
                    }
                    .oterplatform-button {
                        width: 100%;
                    }
                }
                .login-form {
                    max-width: none;
                    margin-top: 20px;
                    margin-bottom: 0;

                    .inputItem {
                        margin-bottom: 0;

                        label {
                            font-size: 14px;
                            top: 16px;
                            left: 16px;
                        }

                        &.active {
                            top: 4px;
                            left: 16px;
                            font-size: 12px;
                        }
                    }

                    .form-item1 {
                    }

                    .form-item2 {
                        font-size: 0.14rem;
                    }

                    .default-input {
                        height: 48px;
                        padding: 16px 0 0 16px;
                        font-size: 14px;
                        html[site='sa'] & {
                            padding: 16px 16px 0 0;
                        }
                    }

                    .iconfont {
                        width: 0.16rem;
                        height: 0.16rem;
                    }

                    .ant-input-suffix {
                        margin: -14 px 10px 0 10px;
                    }

                    .login-form-button {
                        font-size: 14px;
                        height: 44px;
                        & > span {
                            font-family: var(--ff-woff2-B);
                            font-weight: 500;
                        }
                    }

                    .bb {
                        height: 0.22rem;
                    }

                    .line {
                        font-size: 0.14rem;
                    }

                    .login-form-register,
                    .login-form-forgot {
                        html[site='jp'] & {
                            font-size: 0.12rem;
                        }
                        html[site='it'] & {
                            width: 1.5rem;
                            white-space: normal;
                            text-align: center;
                        }
                    }
                }

                .login-form-register-button {
                    height: 44px;
                    font-size: 14px;
                }

                .ant-form-item {
                    margin-bottom: 24px;
                    html[site='za'] & {
                        margin-bottom: 34px;
                    }
                    html[site='jp'] & {
                        margin-bottom: 16px;
                    }
                }

                .ant-form-item.ant-form-item-has-error {
                    margin-bottom: 0;

                    .ant-form-item-explain-error {
                        margin-bottom: 0;
                    }
                }

                .login-form .default-input {
                    width: 100%;
                }

                .login-form .large {
                    width: 100%;
                    font-family: var(--ff-woff2-B);
                }
            }

            .login-box .error-tip {
                left: 0;
                html[site='jp'] & {
                    font-size: 0.12rem;
                    line-height: 0.16rem;
                }
            }
            .login-box .ant-form-item-margin-offset {
                margin-bottom: 0 !important;
            }
        }
    }
}
