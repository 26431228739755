
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
    
.efInput {
    width: 256px;
    height: 42px;
    border-radius: 4px;
    border: 1px solid var(--navbg);
}
.efInput:focus,
.efInput:hover {
    border-color: #121212 !important;
    // box-shadow:0 0 0 2px rgba(3, 6, 11, 0.2);
    box-shadow: none;
}
